<template>
  <div class="ps-detail">
    <div class="psd-title ellipse-1">
      {{ detaiItem.title }}
    </div>
    <div class="psd-des">
      <div class="psd-des-top">
        <div class="psd-des-top-text">
          {{ detaiItem.keywords }}
        </div>
      </div>
      <div class="psd-des-bottom">
        <div class="psddb-left">
          <div>归属高校：{{ detaiItem.colleges }}</div>
          <div style="margin-top: 20px">发明人：{{ detaiItem.expertsCompleting }}</div>
        </div>
        <div class="psddb-right">
          <div class="psddb-right-buttonA display-flex-center" @click="openZoosUrl">
            <img style="margin-top: 3px" width="16" height="16"
                 src="https://cnipy-public.oss-cn-shanghai.aliyuncs.com/cnipy/public/202203/lyg_chat_iconAS.png"/>
            <span style="margin-left: 10px">咨询成果</span>
          </div>
          <div class="psddb-right-buttonB display-flex-center" @click="collectBtn">{{ cStr }}</div>
        </div>
      </div>
    </div>
    <div class="ps-blank">综合介绍</div>
    <div class="ps-text" v-html="detaiItem.abstractDesc"/>
    <div class="ps-blank">应用方向</div>
    <div class="ps-text"> {{ detaiItem.category }}</div>
    <div class="ps-blank">专利情况</div>
    <div class="ps-text"> 无</div>
    <div class="ps-blank">创新要点</div>
    <div class="ps-text"> 无</div>
    <div class="ps-blank">技术指标</div>
    <div class="ps-text">无</div>
  </div>
</template>

<script>
import {
  collect,
  isCollect
} from "@/plugins/api/collect";
import {getLocalToken} from "@/plugins/tools/storage";
import {getResultDetail} from "../../plugins/api/resourceApi";
import {errorToast} from "../../plugins/tools/util";

export default {
  name: "PatentSearchDetail",
  data() {
    return {
      // 关注按钮文字
      cStr: "关注",
      cFlag: true,
      id: "",
      detaiItem: {
        title: '',
        abstractDesc: '',
        keywords: '',
        colleges: '',
        expertsCompleting: '',
        category: ''
      },
    }
  },
  methods: {
    // 关注按钮
    collectBtn() {
      this.checkLogin();
      getLocalToken() && collect({typeId: this.id, type: 2, content: JSON.stringify(this.detaiItem)}).then((data) => {
        if (data.code === 0) {
          this.cFlag = !this.cFlag;
          this.cStr = this.cFlag ? "关注" : "取消关注"
        }
      })
    },
    // 是否关注
    isCollect(id) {
      getLocalToken() && isCollect({typeId: id, type: 2}).then((data) => {
        this.cFlag = data.result;
        this.cStr = this.cFlag ? "关注" : "取消关注";
      });
    },
    checkLogin() {
      if (!getLocalToken()) {
        this.$Modal.confirm({
          draggable: true,
          onOk: async () => {
            this.$router.push({name: 'login'})
          },
          render: (h) => {
            return h('div',
                [h('div', {
                  style: "text-align:center;margin-bottom:20px;padding-top:20px;vertical-align: middle;font-size: 16px;color: #17233d;",
                }, '请先登录后再试')
                ])
          }
        })
        return false
      } else {
        return true
      }
    },
    openZoosUrl() {
      openZoosUrl('chatwin');
    },
  },
  mounted() {
    this.id = this.$route.query.id
    getResultDetail({"id": this.id}).then((res) => {

      if (res.code == 0) {
        this.detaiItem = res.result;
      } else {
        errorToast(res.message)
      }
    })
    this.isCollect(this.id)
  }
}
</script>

<style scoped lang="scss">
.ps-detail {
  width: 1200px;
  margin: 30px auto 50px auto;
  background: linear-gradient(180deg, rgba(42, 139, 239, 0.29), rgba(255, 255, 255, 0) 150px, rgba(255, 255, 255, 0));
  border-radius: 6px;
  box-shadow: 1px 1px 10px rgba(229, 239, 249, 1);
  padding: 32px 50px;
  position: relative;
}

.psd-title {
  font-size: 24px;
  font-weight: bold;
  color: #333333;
  text-align: center;
}

.psd-des {
  background: #FFFFFF;
  border: 1px solid #E2EFFD;
  border-radius: 6px;
  box-shadow: 1px 1px 10px rgba(229, 239, 249, 1);
  margin-top: 28px;
}

.psd-des-top {
  height: 150px;
  margin-left: 24px;
  margin-right: 24px;
  border-bottom: 1px dashed rgba(220, 227, 240, 1);
  padding-top: 17px;
}

.psd-des-top-text {
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  line-height: 30px;
  text-indent: 40px;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  white-space: normal !important;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.psd-des-bottom {
  height: 94px;
  margin-left: 24px;
  margin-right: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.psddb-left {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 400;
  color: #666666;
}

.psddb-right {
  display: flex;
  flex-direction: row;
}

.psddb-right-buttonA {
  width: 112px;
  height: 32px;
  background-color: #0F62DF;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  cursor: pointer;
}

.psddb-right-buttonB {
  width: 112px;
  height: 32px;
  background: #FFFFFF;
  border: 1px solid #0F62DF;
  border-radius: 6px;
  font-size: 14px;
  margin-left: 10px;
  font-weight: 400;
  color: #0F62DF;
  cursor: pointer;
}

.ps-blank {
  width: 120px;
  height: 30px;
  background: linear-gradient(90deg, #0F62DF 0%, #FFFFFF 100%);
  display: flex;
  align-items: center;
  padding-left: 10px;
  margin-top: 50px;
  font-size: 18px;
  font-weight: bold;
  color: #FFFFFF;
}

.ps-text {
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  margin-top: 30px;
  margin-bottom: 50px;
  line-height: 36px;
}
</style>
